import {CartBodyDataHook} from '../../../CartApp/partials/CartBody';
import {classes, st} from './Skeleton.st.css';
import React from 'react';

export const Skeleton = () => (
  <div data-hook={CartBodyDataHook.CartAsideLoader} className={st(classes.asideSkeleton, classes.section)}>
    <div className={st(classes.mainSection, classes.section)}>
      <div className={st(classes.subSection, classes.section)}>
        <div className={classes.row}>
          <div className={st(classes.skeletonLine, classes.medium)}></div>
          <div className={st(classes.skeletonLine, classes.small)}></div>
        </div>
        <div className={classes.row}>
          <div className={st(classes.skeletonLine, classes.large)}></div>
          <div className={st(classes.skeletonLine, classes.small)}></div>
        </div>
        <div className={classes.row}>
          <div className={st(classes.skeletonLine, classes.medium)}></div>
          <div className={st(classes.skeletonLine, classes.small)}></div>
        </div>
      </div>
      <div className={st(classes.subSection, classes.section)}>
        <div className={classes.row}>
          <div className={st(classes.skeletonLine, classes.large)}></div>
          <div className={st(classes.skeletonLine, classes.small)}></div>
        </div>
        <div className={classes.row}>
          <div className={st(classes.skeletonLine, classes.small)}></div>
        </div>
      </div>
      <div className={st(classes.subSection, classes.section)}>
        <div className={classes.row}>
          <div className={st(classes.skeletonLine, classes.medium)}></div>
          <div className={st(classes.skeletonLine, classes.small)}></div>
        </div>
      </div>
    </div>
    <div className={classes.divider} />
    <div className={st(classes.bottomSection, classes.section)}>
      <div className={classes.row}>
        <div className={st(classes.skeletonLine, classes.medium)}></div>
        <div className={st(classes.skeletonLine, classes.small)}></div>
      </div>
      <div className={classes.row}>
        <div className={st(classes.skeletonLine, classes.xl)}></div>
      </div>
    </div>
  </div>
);
